import React from 'react'
const SectionTwo: React.FC<any> = ({scrollToForm}) => {
    
    return (
        <>
            <div className="table-checkout">
                <div className="container-fluid">
                    <h1>Why Choose KwikCheckout’s One-Click
                        Checkout Over Others?</h1>
                    <div className='scroll-view-deskstop'>
                        <table>
                            <tbody>
                                <tr className='sticky-header'>
                                    <th>Value Proposition</th>
                                    <th className='tblbgcolor'>GoKwik KwikCheckout</th>
                                    <th className='tblwidth'>Razorpay Magic Checkout</th>
                                    <th className='tblwidth'>Shiprocket Fastrr Checkout</th>
                                    <th className='tblwidth'>Shopflo Checkout</th>
                                   
                                </tr>
                                <tr className='sticky-subheader'>
                                    <td className='titlepad' colSpan={5}><b>Deepest D2C Shopper Network</b></td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>100 million+ customers with addresses</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                   
                                </tr>
                                <tr>
                                    <td className='titlepad'>{'>80% address prefill rates for returning customers'}</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>{'>40% logged in customers (SSO)'}</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr className='sticky-subheader'>
                                    <td className='titlepad' colSpan={5}><b>Robust Discounting Module</b></td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>
                                    Create bulk discounts
                                    </td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                   
                                </tr>
                                <tr>
                                    <td className='titlepad'>Seamlessly control automatic and manual discounts with multiple combinations</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>
                                    Control coupon application on specific payment methods
                                    </td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>
                                    Support most common use cases: bundle discounts, tiered discounts, discount capping
                                    </td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Control combination of discounts, payment offers, loyalty, rewards, and gift cards seamlessly</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Swiggy-like UI for discounts discoverability</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Manage discount visibility: hide unavailable offers & explain reasons, control user types, set price conditions.</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Stackrank discounts based on multiple conditions</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Show offers upfront based on multiple conditions (best offer, exact offer, worst offer)</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Auto-apply best discount on checkout</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr className='sticky-subheader'>
                                    <td className='titlepad' colSpan={5}><b>Customise Checkout UI For Your Brand</b></td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>Customisable banners, brand colours, CTA on checkout</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Reordering of payment methods</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Customise payment page UI for mobile and desktop separately</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr className='sticky-subheader'>
                                    <td className='titlepad' colSpan={5}><b>Best In Class Shipping Module</b></td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>Support for 3rd party and inhouse pincode serviceability</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Support for multiple 3rd party EDD providers</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Discount code-based shipping</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                   
                                </tr>
                                <tr>
                                    <td className='titlepad'>User-type-based shipping (new/repeat users of your brand)</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Weight-based shipping</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Pincode-specific shipping rates</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Payment-method-specific shipping charges with dynamic COD fee calculation</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr className='sticky-subheader'>
                                    <td className='titlepad' colSpan={5}><b>Deepest Loyalty And Rewards Module In The Ecosystem</b></td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>Integrations with 10 leading loyalty programs</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Manage loyalty points behaviour alongside discounts, prepaid offers, COD, and more</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>1 click redemption with an option for auto-redemption on checkout</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Integrations with Twidpay to power rewards from multiple platforms (e.g. Flipkart Supercoins) to reduce discount burns</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr className='sticky-subheader'>
                                    <td className='titlepad' colSpan={5}><b>Deeper Analytics And Reporting</b></td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>Interactive board with conversion funnel analytics</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Hourly level conversion funnel for real time insights</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Provide conversion funnel split on marketing parameters (UTMs, landing pages, etc)</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                   
                                </tr>
                                <tr>
                                    <td className='titlepad'>Wide variety of detailed reports available on dashboard</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Provide detailed order level breakdown for easier reconciliation</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr className='sticky-subheader'>
                                    <td className='titlepad' colSpan={5}><b>Best In Class Events Tracking Efficacy And Most Integrations</b></td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>Support for multiple pixels and conversion APIs on Meta</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Support for server-side tracking on GA4</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                   
                                </tr>
                                <tr>
                                    <td className='titlepad'>Out-of-box integrations with 15+ marketing automation and pixel enhancement tools</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr className='sticky-subheader'>
                                    <td className='titlepad' colSpan={5}><b>Mobile Apps</b></td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>Support of checkout on mobile apps</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Support UPI intent flow on mobile apps for both iOS and Android</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Provide sales channel-level controls—discounting, shipping, etc</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Provide analytics split on sales channel level</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr className='sticky-subheader'>
                                    <td className='titlepad' colSpan={5}><b>Robust Payments</b></td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>Support for multiple PGs</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Support for multiple BNPL</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Exclusive co-funded and fully funded payment offers (e.g. One Card, Cred, etc)</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Support Shopify gift cards natively on checkout</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Multiple gift card and voucher integrations—Gyftr, Quicksilver, etc</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Support for split COD payments with most control levers (SKU-based, risk profile-based, cart value-based)</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Support native payments with minimal redirection</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Payment assurance via OFPL flow</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Consolidated settlement reports</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr className='sticky-subheader'>
                                    <td className='titlepad' colSpan={5}><b>Reduce RTO & Frauds</b></td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>Deepest RTO data for risk profiling</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>RTO action workflow with deepest segmentation and control levers</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Most RTO actions availability: blocking on risk level, prompts, captcha, OTPs, etc.</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>RTO insurance</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr className='sticky-subheader'>
                                    <td className='titlepad' colSpan={5}><b>Coverage for most use cases</b></td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>Support GST input along with validations</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Support robust tax override modules (IGST, CGST, SGST) on a product/state/shipping basis</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Inventory adjustment controls</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                   
                                </tr>
                                <tr>
                                    <td className='titlepad'>Kwikpass solution</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Kwikchat solution</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Side cart</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                </tr>
                                <tr>
                                    <td className='titlepad'>Truecaller integration</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>

                    <div className='scroll-view-mobile'>
                        <div className='scroll-table'>
                            <table>
                                <tbody>
                                    <tr className=''>
                                        <th style={{minWidth: '160px'}}>Deepest D2C Shopper Network</th>
                                        <th className='tblbgcolor'>GoKwik KwikCheckout</th>
                                        <th className='tblwidth'>Razorpay Magic Checkout</th>
                                        <th className='tblwidth'>Shiprocket Fastrr Checkout</th>
                                        <th className='tblwidth'>Shopflo Checkout</th>
                                    </tr>
                                    
                                    <tr>
                                        <td className='titlepad'>100 million+ customers with addresses</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>{'>80% address prefill rates for returning customers'}</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>{'>40% logged in customers (SSO)'}</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mobileview ">
                                     <a className='pointer' onClick={scrollToForm}>
                                        <button className="button-cta animate">Get A Free Demo</button>
                                    </a>
                                </div>
                        <div className='scroll-table'>
                            <table>
                                <tbody>
                                    <tr className=''>
                                        <th style={{minWidth: '160px'}}>Robust Discounting Module</th>
                                        <th className='tblbgcolor'>GoKwik KwikCheckout</th>
                                        <th className='tblwidth'>Razorpay Magic Checkout</th>
                                        <th className='tblwidth'>Shiprocket Fastrr Checkout</th>
                                        <th className='tblwidth'>Shopflo Checkout</th>
                                    </tr>

                                    <tr>
                                        <td className='titlepad'>
                                        Create bulk discounts
                                        </td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Seamlessly control automatic and manual discounts with multiple combinations</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>
                                        Control coupon application on specific payment methods
                                        </td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>
                                        Support most common use cases: bundle discounts, tiered discounts, discount capping
                                        </td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Control combination of discounts, payment offers, loyalty, rewards, and gift cards seamlessly</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Swiggy-like UI for discounts discoverability</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Manage discount visibility: hide unavailable offers & explain reasons, control user types, set price conditions.</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Stackrank discounts based on multiple conditions</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Show offers upfront based on multiple conditions (best offer, exact offer, worst offer)</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Auto-apply best discount on checkout</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    </tbody>
                            </table>
                        </div>
                            <div className="mobileview ">
                                     <a className='pointer' onClick={scrollToForm}>
                                        <button className="button-cta animate">Get A Free Demo</button>
                                    </a>
                                </div>
                        <div className='scroll-table'>
                            <table>
                                <tbody>
                                    <tr className=''>
                                        <th style={{minWidth: '160px'}}>Customise Checkout UI For Your <br/>Brand</th>
                                        <th className='tblbgcolor'>GoKwik KwikCheckout</th>
                                        <th className='tblwidth'>Razorpay Magic Checkout</th>
                                        <th className='tblwidth'>Shiprocket Fastrr Checkout</th>
                                        <th className='tblwidth'>Shopflo Checkout</th>
                                    </tr>
                                    
                                    <tr>
                                        <td className='titlepad'>Customisable banners, brand colours, CTA on checkout</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Reordering of payment methods</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Customise payment page UI for mobile and desktop separately</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    </tbody>
                            </table>
                        </div>
                        <div className="mobileview ">
                                     <a className='pointer' onClick={scrollToForm}>
                                        <button className="button-cta animate">Get A Free Demo</button>
                                    </a>
                                </div>
                        <div className='scroll-table'>
                            <table>
                                <tbody>
                                    <tr className=''>
                                        <th style={{minWidth: '160px'}}>Best In Class Shipping Module</th>
                                        <th className='tblbgcolor'>GoKwik KwikCheckout</th>
                                        <th className='tblwidth'>Razorpay Magic Checkout</th>
                                        <th className='tblwidth'>Shiprocket Fastrr Checkout</th>
                                        <th className='tblwidth'>Shopflo Checkout</th>
                                    </tr>
                                    
                                    <tr>
                                        <td className='titlepad'>Support for 3rd party and inhouse pincode serviceability</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Support for multiple 3rd party EDD providers</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Discount code-based shipping</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>User-type-based shipping (new/repeat users of your brand)</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Weight-based shipping</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Pincode-specific shipping rates</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Payment-method-specific shipping charges with dynamic COD fee calculation</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    </tbody>
                            </table>
                        </div>
                        <div className="mobileview ">
                                     <a className='pointer' onClick={scrollToForm}>
                                        <button className="button-cta animate">Get A Free Demo</button>
                                    </a>
                                </div>
                        <div className='scroll-table'>
                            <table>
                                <tbody>
                                    <tr className=''>
                                        <th style={{minWidth: '160px'}}>Deepest Loyalty And Rewards <br/>Module In The Ecosystem</th>
                                        <th className='tblbgcolor'>GoKwik KwikCheckout</th>
                                        <th className='tblwidth'>Razorpay Magic Checkout</th>
                                        <th className='tblwidth'>Shiprocket Fastrr Checkout</th>
                                        <th className='tblwidth'>Shopflo Checkout</th>
                                    </tr>
                                    
                                    <tr>
                                        <td className='titlepad'>Integrations with 10 leading loyalty programs</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Manage loyalty points behaviour alongside discounts, prepaid offers, COD, and more</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>1 click redemption with an option for auto-redemption on checkout</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Integrations with Twidpay to power rewards from multiple platforms (e.g. Flipkart Supercoins) to reduce discount burns</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    </tbody>
                            </table>
                        </div>
                        <div className="mobileview ">
                                     <a className='pointer' onClick={scrollToForm}>
                                        <button className="button-cta animate">Get A Free Demo</button>
                                    </a>
                                </div>
                        <div className='scroll-table'>
                            <table>
                                <tbody>
                                    <tr className=''>
                                        <th style={{minWidth: '160px'}}>Deeper Analytics And Reporting</th>
                                        <th className='tblbgcolor'>GoKwik KwikCheckout</th>
                                        <th className='tblwidth'>Razorpay Magic Checkout</th>
                                        <th className='tblwidth'>Shiprocket Fastrr Checkout</th>
                                        <th className='tblwidth'>Shopflo Checkout</th>
                                    </tr>
                                    
                                    <tr>
                                        <td className='titlepad'>Interactive board with conversion funnel analytics</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Hourly level conversion funnel for real time insights</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Provide conversion funnel split on marketing parameters (UTMs, landing pages, etc)</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Wide variety of detailed reports available on dashboard</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Provide detailed order level breakdown for easier reconciliation</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    </tbody>
                            </table>
                        </div>
                        <div className="mobileview ">
                                     <a className='pointer' onClick={scrollToForm}>
                                        <button className="button-cta animate">Get A Free Demo</button>
                                    </a>
                                </div>
                        <div className='scroll-table'>
                            <table>
                                <tbody>
                                    <tr className=''>
                                        <th style={{minWidth: '160px'}}>Best In Class Events Tracking <br/>Efficacy And Most Integrations</th>
                                        <th className='tblbgcolor'>GoKwik KwikCheckout</th>
                                        <th className='tblwidth'>Razorpay Magic Checkout</th>
                                        <th className='tblwidth'>Shiprocket Fastrr Checkout</th>
                                        <th className='tblwidth'>Shopflo Checkout</th>
                                    </tr>
                                    
                                    <tr>
                                        <td className='titlepad'>Support for multiple pixels and conversion APIs on Meta</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Support for server-side tracking on GA4</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                    
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Out-of-box integrations with 15+ marketing automation and pixel enhancement tools</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    </tbody>
                            </table>
                        </div>
                        <div className="mobileview ">
                                     <a className='pointer' onClick={scrollToForm}>
                                        <button className="button-cta animate">Get A Free Demo</button>
                                    </a>
                                </div>
                        <div className='scroll-table'>
                            <table>
                                <tbody>
                                    <tr className=''>
                                        <th style={{minWidth: '160px'}}>Mobile Apps</th>
                                        <th className='tblbgcolor'>GoKwik KwikCheckout</th>
                                        <th className='tblwidth'>Razorpay Magic Checkout</th>
                                        <th className='tblwidth'>Shiprocket Fastrr Checkout</th>
                                        <th className='tblwidth'>Shopflo Checkout</th>
                                    </tr>
                                    
                                    
                                    <tr>
                                        <td className='titlepad'>Support of checkout on mobile apps</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Support UPI intent flow on mobile apps for both iOS and Android</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Provide sales channel-level controls—discounting, shipping, etc</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Provide analytics split on sales channel level</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    </tbody>
                            </table>
                        </div>
                        <div className="mobileview ">
                                     <a className='pointer' onClick={scrollToForm}>
                                        <button className="button-cta animate">Get A Free Demo</button>
                                    </a>
                                </div>
                        <div className='scroll-table'>
                            <table>
                                <tbody>
                                    <tr className=''>
                                        <th style={{minWidth: '160px'}}>Robust Payments</th>
                                        <th className='tblbgcolor'>GoKwik KwikCheckout</th>
                                        <th className='tblwidth'>Razorpay Magic Checkout</th>
                                        <th className='tblwidth'>Shiprocket Fastrr Checkout</th>
                                        <th className='tblwidth'>Shopflo Checkout</th>
                                    </tr>
                                    
                                    <tr>
                                        <td className='titlepad'>Support for multiple PGs</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Support for multiple BNPL</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Exclusive co-funded and fully funded payment offers (e.g. One Card, Cred, etc)</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Support Shopify gift cards natively on checkout</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Multiple gift card and voucher integrations—Gyftr, Quicksilver, etc</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Support for split COD payments with most control levers (SKU-based, risk profile-based, cart value-based)</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Support native payments with minimal redirection</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Payment assurance via OFPL flow</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Consolidated settlement reports</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mobileview ">
                                     <a className='pointer' onClick={scrollToForm}>
                                        <button className="button-cta animate">Get A Free Demo</button>
                                    </a>
                                </div>
                        <div className='scroll-table'>
                            <table>
                                <tbody>
                                    <tr className=''>
                                        <th style={{minWidth: '160px'}}>Robust Payments</th>
                                        <th className='tblbgcolor'>GoKwik KwikCheckout</th>
                                        <th className='tblwidth'>Razorpay Magic Checkout</th>
                                        <th className='tblwidth'>Shiprocket Fastrr Checkout</th>
                                        <th className='tblwidth'>Shopflo Checkout</th>
                                    </tr>
                                    
                                    <tr>
                                        <td className='titlepad'>Deepest RTO data for risk profiling</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>RTO action workflow with deepest segmentation and control levers</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Most RTO actions availability: blocking on risk level, prompts, captcha, OTPs, etc.</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>RTO insurance</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mobileview ">
                                     <a className='pointer' onClick={scrollToForm}>
                                        <button className="button-cta animate">Get A Free Demo</button>
                                    </a>
                                </div>
                        
                        <div className='scroll-table'>
                            <table>
                                <tbody>
                                    <tr className=''>
                                        <th style={{minWidth: '160px'}}>Coverage for most use cases</th>
                                        <th className='tblbgcolor'>GoKwik KwikCheckout</th>
                                        <th className='tblwidth'>Razorpay Magic Checkout</th>
                                        <th className='tblwidth'>Shiprocket Fastrr Checkout</th>
                                        <th className='tblwidth'>Shopflo Checkout</th>
                                    </tr>
                                    
                                    <tr>
                                        <td className='titlepad'>Support GST input along with validations</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Support robust tax override modules (IGST, CGST, SGST) on a product/state/shipping basis</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Inventory adjustment controls</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                    
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Kwikpass solution</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Kwikchat solution</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Side cart</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        
                                    </tr>
                                    <tr>
                                        <td className='titlepad'>Truecaller integration</td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png'  /></td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mobileview ">
                                     <a className='pointer' onClick={scrollToForm}>
                                        <button className="button-cta animate">Get A Free Demo</button>
                                    </a>
                                </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SectionTwo